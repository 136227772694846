import React, { useRef, useState } from "react";
import logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Checkbox, Divider, Form, Input, Select, Space } from "antd";
import AuthSideImage from "../../../../components/auth/AuthSideImage";
import { setProjectDescription } from "../../../../features/wsp_registration/wspRegSlice";
import numeral from "numeral";

const NumberInput = ({ value = "", onChange }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const unformattedValue = inputValue.replace(/,/g, "");
    const formatted = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    onChange(formatted);
  };

  return (
    <Input
      prefix={
        <div className="site-form-item-icon flex items-center text-gray">
          KES
          <span class="w-[18px]   rotate-90 border border-[#636363] border-opacity-50"></span>
        </div>
      }
      value={value}
      onChange={handleInputChange}
      placeholder="How much would it cost?"
      className="reg-input"
    />
  );
};

function ProjectDescriptionSignUp() {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { projectDescription } = useSelector((state) => state.wspReg);

  const [data, setdata] = useState({});
  const [fieldError, setFieldError] = useState(false);

  const onChangeCost = (e) => {
    let { name, value } = e.target;
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const numericValue = Number(cleanedValue).toString();
    const formattedValue = numeral(numericValue).format("0,0");

    setdata((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [formattedValue, setFormattedValue] = useState("");

  const handleFormInputChange = (value) => {
    setFormattedValue(value);
  };

  const onFinish = async (values) => {
    values.projImpact = Array.isArray(values?.projImpact) ? values.projImpact.join(",") : values.projImpact;
    await dispatch(setProjectDescription(values));
    await navigate("/signup-wsp/password-setup");
  };

  return (
    <>
      <div className="lg:grid lg:grid-cols-6">
        <div className="bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block">
          <AuthSideImage hideStepper={false} hideText={false} current={3} />
        </div>
        <div className="lg:col-span-4 ">
          <div
            className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[88px] items-center justify-center flex cursor-pointer"
            onClick={() => navigate("/signup-wsp/management-details")}
          >
            <img src={leftArrow} alt="backarrow" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={logo} alt="logo" style={{ marginTop: "10px" }}  onClick={()=>navigate('/')}/>
            <div class="text-center text-blackest text-[28px] font-bold font-dmSans leading-[44px] mt-[25px]">
              Let’s get started
            </div>
            <div class="w-[497px] text-center text-gray text-[14px] font-normal font-dmSans leading-tight">
              All we need is a few company details. <br />
              Then you'll be set up and able to apply for financing.
            </div>
            <div className="mt-[39px] flex justify-center">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                className="lg:w-[700px] w-auto"
                form={form}
                initialValues={{
                  requiredMarkValue: false,
                  ...projectDescription,
                }}
                requiredMark={false}
              >
                <div className="flex flex-col mb-[-23px]">
                  <Form.Item
                    extra="Give a brief overview"
                    name="projDesc"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please describe the project you wish to undertake!",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Describe the project you wish to undertake"
                      rows={4}
                      className="reg-input wsp-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra="Give an estimate of much you would need in KES"
                    rules={[
                      {
                        required: true,
                        message:
                          "Give an estimate of much you would need in KES",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <div className="site-form-item-icon flex items-center text-gray">
                          KES
                          <span class="w-[18px] rotate-90 border border-[#636363] border-opacity-50"></span>
                        </div>
                      }
                      name="projCost"
                      value={data?.projCost}
                      required
                      placeholder="How much would it cost?"
                      min={0}
                      type="text"
                      onChange={onChangeCost}
                      className="reg-input"
                    />
                  </Form.Item>

                  {/* <Form.Item
                    name="projCost"
                    extra="Give an estimate of much you would need in KES"
                    rules={[
                      {
                        required: true,
                        message:
                          "Give an estimate of much you would need in KES",
                      },
                    ]}
                  >
                    <NumberInput
                      value={formattedValue}
                      onChange={handleFormInputChange}
                    />
                  </Form.Item> */}

                  <Form.Item
                    extra={"Choose one or more options"}
                    name="projImpact"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        height: "52px",
                      }}
                      placeholder={"What is the purpose of financing?"}
                      size="large"
                      className=""
                      dropdownRender={(menu) => (
                        <>
                          <Space
                            style={{
                              padding: "8px 8px 4px",
                            }}
                          >
                            <span className="select-info mt-1">
                              You may choose one or more of the following
                            </span>
                          </Space>
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          {menu}
                        </>
                      )}
                      menuItemSelectedIcon={({ isSelected }) => (
                        <>
                          {isSelected ? (
                            <Checkbox checked={true}></Checkbox>
                          ) : (
                            <Checkbox checked={false}></Checkbox>
                          )}
                        </>
                      )}
                    >
                      <Select.Option
                        onClick={() =>
                          console.log("Increased Affordable and clean water")
                        }
                        value={"Increase supply"}
                      >
                        Increase supply
                      </Select.Option>
                      <Select.Option value={"Increase supply area"}>
                        Increase supply area
                      </Select.Option>
                      <Select.Option value={"Improve quality"}>
                        Improve quality
                      </Select.Option>
                      <Select.Option value={"Improve reliability"}>
                        Improve reliability
                      </Select.Option>
                      <Select.Option value={"Metering"}>Metering</Select.Option>
                      <Select.Option value={"Reduce operating costs"}>
                        Reduce operating costs
                      </Select.Option>
                      <Select.Option value={"Increase customers"}>
                        Increase customers
                      </Select.Option>
                      <Select.Option value={"Reduce leaks"}>
                        Reduce leaks
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="text-center mt-[46px]">
                  <button
                    className="w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                       text-white text-[18px] font-medium font-dmSans leading-normal"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </Form>
            </div>
            <div className="bottom-1 mt-10 mb-[30px] text-center text-darkBlue text-[14px] font-normal font-['Inter'] leading-snug">
              Privacy Policy • Terms and Conditions
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDescriptionSignUp;
